@import "colors.scss";
.book-details-card {
  width: 100%;
  flex-flow: row;
  margin-top: 15px;
  padding-bottom: 20px;
  & > div {
    box-sizing: border-box;
  }
  .book-description {
    padding-right:35px;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 450px;
    flex-shrink: 0;
    max-width: 900px;
    margin-bottom: 30px;
    hr {
      overflow: visible; /* For IE */
      padding: 0;
      border: none;
      border-top: 1px solid #efefef;
      color: #e8e8e8;
      font-family: serif;
      text-align: left;
      line-height: 30px;
    }
    hr:after {
      content: "✿"; /*☆ ✿*/
      display: inline-block;
      position: relative;
      top: -0.7em;
      font-size: 1.5em;
      padding: 0 1px 0 0;
      background: white;
    }
  }
  ul#details li {
    font-size: 1.4rem;
    list-style: none;
    width: 50%;
    padding-right: 15px;
    box-sizing: border-box;
    span {
      font-weight: 500;
    }
  }
  .mdl-card__media {
    flex-basis: 250px;
    flex-shrink: 0;
    background-color: inherit;
    padding: 0 35px 0 0;
    margin-bottom: 40px;
    max-width: 350px;
    a {
      flex-basis: 100%;
      img {
        max-width: 100%;
      }
    }
    div.thumbs {
      display: flex;
      justify-content: space-between;
      flex-flow: row;
      flex-wrap: wrap;
      a {
        margin-top: 5px;
        flex-basis: 48%;
      }
    }
    .missing-cover {
      margin: 0;
    }
  }
  i.missing-cover {
    height: 200px;
    font-size: 8.4rem;
    color: $missingBookCoverText;
    background-color: $missingBookCoverBackground;
    height: 300px;
    width: 220px;
    text-align: center;
    line-height: 300%;
    margin: 20px;
    display: block;
  }

  & > div {
    flex-flow: column;
  }
  .mdl-card__title {
    align-items: flex-start;
    color: inherit;
    padding:  0;
    display: block;
    * {
      margin: 0;
    }
    h1 {
      line-height: 3.4rem;
    }
    h2 {
      line-height: 2.4rem;
      margin-top: 8px;
    }
    h3 {
      margin: 16px 0 0 0;
      font-size: 2rem;
    }
    .sequence {
      font-size: 2rem;
      border-bottom: 1px solid #ccc;
      text-align: right;
      padding: 0 0 8px 0;
      margin-bottom: 16px;
      line-height: 2rem;
    }
  }
  .mdl-chip {
    background-color: $buttonColoredRound;
    color: $textHeader;
    line-height: 32px;
    background-image: url(/assets/images/khaki.jpg);
    background-size: 160px auto;
  }
  .mdl-chip__text {
    font-size: 1.4rem;
    vertical-align: baseline;
    font-variant-caps: small-caps;
    font-weight: 500;
    line-height: 2.6rem;
  }
  .book-info {
    color: inherit;
    padding: 0;
    font-size: 1.3rem;
    width: 100%;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }

    p {
      font-size: 1.5rem;
    }
  }

  .book-details-bookitems {
    flex-basis: 270px;
    flex-shrink: 0;
    flex-grow: 1;
    min-width: 300px;
    max-width: 500px;
    & > div {
      margin-bottom: 40px;
    }
    .mdl-button--fab{
      background-image: url(/assets/images/aubergine_btn.jpg);
      background-size: 180px auto;
    }
    ul {
      margin: 0;
      padding: 0;
      .price{
        text-align: right;
        font-size: 1.8rem;
        font-weight: 500;
      }
      li {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 1.5rem;
        > div {
          font-size:1.5rem;
          flex-basis: 70%;
        }
        button {
          width: 100%;
          span {
            line-height: 40px;
            margin-right: 5px;
          }
          i {
            margin-bottom: 5px;
          }
        }
      }
    }
    h4 {
      margin-top: 0;
      font-size: 2rem;
      line-height: 2rem;
    }
    hr {
      width: 100%;
      border-top: 1px solid #efefef;
    }
    #messageForm {
      div.mdl-textfield {
        width: 100%;
      }
    }

    .help-link {
      display: block;
      font-size: 2rem;
      cursor: pointer;
      margin-bottom: 20px;
      i {
        vertical-align: text-bottom;
        font-size: 2.5rem;
        margin-right: 10px;
      }
    }
    .help-content {
      display: none;
    }
  }

  form {
    p {
      margin-bottom: 0;
    }
    .mdl-button {
      width: 100%;
    }
  }
}
.alert-message {
  color: $error !important;
  border: 1px dashed $headerLine2;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media screen and (max-width: 1050px) {
  main {
    //padding: 10px 20px !important;
  }
  .book-details-card {
    .mdl-card__media {
      flex-basis: 15%;
      padding: 0 25px 0 0;
      flex-grow: 1;
      .missing-cover {
        width: auto;
      }
    }
    .book-description {
      padding-right: 25px;
    }
  }
}
@media screen and (max-width: 940px) {
  .book-details-card {
    flex-wrap: wrap;
    .book-description {
      order: 1;
      padding-right: 0;
      flex-basis: 100%;
    }
    .mdl-card__media {
      order: 2;
      margin: 0 auto 40px auto;
      .missing-cover {
        //display: none;
      }

      div.thumbs a.thumb {
      }
    }
    .book-details-bookitems {
      order: 3;
      margin: 0 auto;
      flex-basis: 300px !important;
      hr {

      }
    }
  }
}
@media screen and (max-width: 700px) {
  main {
    padding: 10px !important;
  }
  .book-details-card {
    flex-wrap: wrap;
    .mdl-card__media {
      order: 2;
      flex-basis: 100%;
      .missing-cover {
        display: none;
      }
      div.thumbs a.thumb {
        flex-basis: auto;
      }
    }
    .book-description {
      order: 1;
      flex-basis: 100%;
      padding-right: 0;
    }
    .book-details-bookitems {
      margin-left: 0;
      order: 3;
      flex-basis: 100%;
      margin: 0 auto;
    }
  }
}

.featherlight-image {
  cursor: zoom-in;
}
.featherlight-content::after {
  content: " ";
  height: 1px;
  display: block;
  clear: both;
}
.featherlight.zoom {
  overflow: auto;
  .featherlight-content {
    overflow: initial;
    height: auto;
    max-height: none;
  }
  .featherlight-image {
    cursor: zoom-out;
    width: auto !important;
    height: auto !important;
  }
}
@media screen and (max-width: 550px) {
  .book-details-card {
    ul#details {
      li {
        width: 100%;
      }
    }
    .mdl-card__media {
      padding-right: 0;
    }
  }
}
