@import "colors";
#authorsList {
  max-width: 1900px;
  ol.alphabet {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
    li {
      margin: 0 3px 3px 0;
      background-color: rgba($button, .9);
      color: white;
      font-size: 1.5rem;
      line-height: 1.5rem;
      a {
        color: white;
        display: block;
        padding: 5px 10px;
        line-height: 2.2rem;
      }
    }
    li.inactive {
      color: grey;
      background-color: rgba(97,97,97,0.1);
      cursor: default;
      padding: 10px 10px 5px;
    }
  }
  li {
    list-style: none;
  }
  ol.authors-list {
    margin: 0;
    padding: 1rem 0 0 0;
    li {
      margin: 5px 10px 5px 0;
      a {
        font-size: 1.7rem;
        font-weight: 500;
      }
    }
    > li {
      font-size: 2.3rem;
      font-weight: 500;
    }
  }
}