@import "colors.scss";
@import "bookPreview.scss";
@import "book.scss";
@import "authors.scss";
@font-face {
    font-family: 'BloggerSans';
    src: url('/assets/fonts/BloggerSans-Light.eot');
    src: url('/assets/fonts/BloggerSans-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/BloggerSans-Light.woff2') format('woff2'),
        url('/assets/fonts/BloggerSans-Light.woff') format('woff'),
        url('/assets/fonts/BloggerSans-Light.svg#BloggerSans-Light') format('svg');
    font-weight: 400;
    font-style: normal;
}
/* Webfont: BloggerSans-Regular */
@font-face {
  font-family: 'BloggerSans';
  src: url('/assets/fonts/BloggerSans-Regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/BloggerSans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/BloggerSans-Regular.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/BloggerSans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/BloggerSans-Regular.svg#BloggerSans-Medium') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 500;
}
/* Webfont: BloggerSans-Medium */
@font-face {
  font-family: 'BloggerSans';
  src: url('/assets/fonts/BloggerSans-Medium.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/BloggerSans-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/BloggerSans-Medium.woff2') format('woff2'), /* Modern Browsers */
  url('/assets/fonts/BloggerSans-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/BloggerSans-Medium.svg#BloggerSans-Medium') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 600;
}

html {
  font-size: 12px;
}
body {
  background-color: $background;
  color: $text;
  p {
    font-size: 1.5rem;
  }
  a {
    text-decoration: none;
    color: $link;
  }
  a:hover {
    text-decoration: underline;
  }
  form {
    .mdl-textfield__input {
      font-size: 1.5rem;
    }
    .mdl-textfield__label {
      color: rgba(0,0,0,.55);
    }
    .mdl-textfield__label, .mdl-textfield.is-invalid label {
      font-size: 1.5rem !important;
    }
    .mdl-textfield__error {
      font-size: 1.3rem !important;
    }
    .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
    .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
    .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
      font-size: 1.3rem;
      color: rgb(97,58,90);
    }
    .mdl-textfield--floating-label.is-focused .mdl-textfield__label:after {
      background-color: rgb(97,58,90);
    }
  }
  form.busy {
    button {
      display: none;
    }
    div.mdl-spinner {
      display: block;
      margin: 0 auto;
    }
  }
  #cookieWarning {
    background-color: $cookieBackground;
    max-width: 300px;
    min-height: auto;
    position: fixed;
    bottom: 0;
    right: 40px;
    z-index: 1000;
    p {
      font-size: 1.3rem;
    }
    .mdl-card__supporting-text {
      color: $cookieText;
    }
    #cookieAcceptButton {
      width: 100%;
    }
    a {
      color: $cookieLink;
      text-decoration: underline;
    }
    a:hover {
      text-decoration: none;
    }
  }
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
main {
  width: 100%;
  box-sizing: border-box;
  padding: 85px 40px 10px 40px;
  margin-top: 85px;
  .demo-card-wide {
    overflow: visible;
  }
  @media screen and (max-width: 700px) {
    padding: 0 10px;
    #cookieWarning {
      right: 10px;
    }
  }
  -webkit-transition: margin 100ms ease-in, padding 100ms ease-in; /* Safari */
  transition: margin margin 100ms ease-in, padding 100ms ease-in;
}
.is-compact + main{
  padding-top: 85px;
}
body h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}
body h2 {
  font-size: 2.5rem;
}

.mdl-layout__header {
  background-image: url(/assets/images/ruby.jpg);
  background-size: 200px;
  background-color: $headerLine2;
  position: absolute;
  top: 0;

  #headTop {
    background-color: $headerLine1;
    height: 35px;
    -webkit-transition: height 100ms; /* Safari */
    transition: height 100ms;
    transition-timing-function: ease-in;
    .profile-icon {
      padding: 0;
      min-width: fit-content;
      margin-right: -8px;
      margin-top: 7px;
      i {
        font-size: 2.3rem;
        color: $textHeader;
      }
    }
  }

  #headMain {
    span {
      a {
        color: $linksButtonsHeader;
        font-weight: 400;
        span {
          vertical-align: bottom;
        }
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
}

.mdl-layout__header.is-compact {
    max-height: 163px;
    #headTop {
      overflow: hidden !important;
      height: 0;
    }
    #categories {
      height: 40px;
      position: relative;
    }
}
.basket {
  color: $text;
  font-size: 1.2rem;
  &:hover #basketDropdown {
    display: block;
  }
  i {
    color: $basketIcon;
    font-size: 2.3rem;
    vertical-align: bottom;
  }
}
.mdl-checkbox {
  display: inline-table;
  margin-bottom: 10px;
  .mdl-checkbox__label {
    font-size: 1.5rem;
  }
}

.basket #basketDropdown {
  display: none;
  position: absolute;
  top: inherit;
  right: 46px;
  opacity: 1;
  background-color: $basketHoverBackground;
  z-index: 999;
  clip: auto !important;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
  max-width: 350px;
}
.basket #basketDropdown {
  > p {
    font-size: 1.6rem;
    margin: 2rem;
  }
  ul {
    padding: 25px;
    margin: 0;
    max-height: 300px;
    overflow: auto !important;
    li:first-child {
      margin-top: 0;
    }
    li:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    li {
      list-style: none;
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #e1e1e1;
      padding: 10px 0;
      img {
        height: auto;
        max-width:60px;
        margin-right: 5px;
      }
      i.missing-cover {
        min-width: 60px;
        max-height: 90px;
        font-size: 3rem;
        line-height: 6.5rem;
        color: $missingBookCoverText;
        background-color: $missingBookCoverBackground;
        margin: 0 5px 0 0;
        text-align: center;
      }
      > div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        p {
          margin-bottom: 0;
          font-size: 1.5rem;
          &.author {
            text-transform: uppercase;
            font-size: 1.2rem;
          }
        }
        .price {
          font-size: 1.5rem;
          text-align: right;
        }
      }
    }
  }
  .basket-total {
    position: relative;
    top: -25px;
    z-index: 1;
    margin: 0 0 -12px 0;
    font-size: 1.8rem;
    font-weight: 400;
    padding-top: 10px;
    text-align: center;
    //background-position: top center;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+17 */
    //background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 17%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    //background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 17%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 17%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    span {
      font-weight: 500;
    }
    &::before {
      content: ' ';
      height: 1px;
      width: 100%;
      display: block;
      background-color: #e1e1e1;
      margin: 20px 0 15px;
    }
  }

  .basket-actions {
    display: flex;
    padding: 0 25px 25px;
  }
}
.mdl-button--raised.mdl-button--colored {
    background-image: url(/assets/images/ruby_btn.jpg);
    background-size: 180px;
    background-color: $buttonColored;
}
.mdl-button--raised.mdl-button--accent {
  background-image: url(/assets/images/aubergine_btn.jpg);
  background-size: 180px;
  background-color: $button !important;
}
.mdl-button--fab.mdl-button--colored {
  background-color: $buttonColoredRound;
  background-image: url(/assets/images/khaki.jpg);
  background-size: 180px;
}


.mdl-button--raised {
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
}


.mdl-tooltip {
	background-color: $tooltips !important;
	font-weight: 500;
	font-size: 1rem;
	z-index: 7000;
}
.grow {
	flex-grow: 1;
}
.grow-2 {
	flex-grow: 2;
}
#search-field {
	background-color: $background;
	color: $footerBackground;
	padding: 8px 0 0 5px;
    box-sizing: border-box;
    font-size: 1.35rem;
}
.advanced-search{
  color: $textHeader;
  font-size: 1.2rem;
  margin-top: -2px;
  display:block;
  float: right;
}
.search-box .mdl-textfield {
  margin-top: 9px;
  padding: 0;
  .mdl-button {
    bottom: 9px;
    right: -44px;
    color: $linksButtonsHeader;
  }
}
.basket {
  .mdl-badge.mdl-badge--overlap {
    top: 2px !important;
    margin-right: 3px;
  }
  .mdl-badge.mdl-badge--overlap:after {
    right: -16px !important;
  }

  .mdl-badge[data-badge]:after {
    top: -7px;
    background: $basketBadge;
    font-size: 1.1rem;
    -webkit-transition: transform 0.8s, opacity 0.8s;
    transition: transform 0.8s;
    font-family: sans-serif;
  }

  &.busy {
    .mdl-badge[data-badge]:after {
      transform: scale(2);
      -webkit-transition: transform 0s, opacity 0s;
      transition: transform 0s;
      opacity: 0.3;
    }
  }
}
.mdl-layout .mdl-layout__header-row {
  padding-left: 40px;
}

#categories {
  background-color: $background;
  border-bottom: 1px solid #efefef;
  .subcategory.mdl-menu {
    background-color: $background;
    .mdl-menu__item {
      font-size: 1.25rem;
    }
    .mdl-menu__item:hover, .mdl-menu__item.active {
      background-color: $basketBadge;
      a {
        color: $textHeader;
        text-decoration: none;
      }
    }
  }
  &.mdl-layout__header-row ul.category {
    padding: 0;
    margin: 8px 0 0 0;
    display: flex;
    li, li a {
      color: $text;
    }
    > li {
      list-style: none;
      margin-right: 40px;
      font-size: 1.25rem;
      &.menu-icon {
        i.material-icons {
          color: $headerLine2;
        }
        i.material-icons::before {
          content: "menu";
        }
      }
      i.material-icons {
        margin-top: -3px;
        margin-left: -3px;
        vertical-align: middle;
        color: $basketBadge;
      }
      .hamburger-menu, .subcategory.mdl-menu {
        box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        display: none;
        opacity: 1;
        z-index: 999;
        clip: auto !important;
        top: inherit;
        left: inherit;
        .mdl-menu__item {
          opacity: 1;
          height: auto;
          line-height: 32px;
          white-space: normal;
          a {
            display: block;
          }
        }
        &.is-visible {
          display: block !important;
        }
      }
      .subcategory {
        -webkit-columns: 300px 3;
        -moz-columns: 300px 3;
        columns: 300px 3;
        -webkit-column-gap: 0px; /* Chrome, Safari, Opera */
        -moz-column-gap: 0px; /* Firefox */
        column-gap: 0px;
      }

      &:hover {
        i.material-icons {
          color: $headerLine2;
          cursor: pointer;
        }
      }
    }
    > li:first-child {
      padding: 0;
      margin-top: 10px;
    }
  }
  .menu-item.active {
    > a {
      span {
        border-bottom: 2px solid $basketBadge;
      }
      text-decoration: none;
    }
  }
  .menu-item {
    position: inherit !important;
  }
  .menu-item-more {
    font-weight: 500;
    display: none;
  }
}

section.article {
  p {
    font-size: 1.4rem;
  }
  ol, ul {
    font-size: 1.4rem;
  }
  h1 {
    font-size: 2rem;
    margin-bottom: 0;
  }
  h2 {
    font-size: 1.8rem;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  img {
    width: 50%;
    margin-top: 15px;
    display: block;
  }
  .img_caption {
    width: 50%;
    font-size: 1.1rem;
    line-height: normal;
    margin: 5px 0 15px;
  }
}

section.collapsible {
  overflow: hidden;
  height: 100px;
}

section.collapsible.opened {
  height: auto;
  a.collapse {
    float: right;
  }
}


header, header *{
  overflow: visible !important;
}
footer {
  background-image: url(/assets/images/aubergine.jpg);
  background-size: 200px;
  background-color: $footerBackground;
  color: $footerText;
  padding: 40px 40px 0 40px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1.4rem;
  ul {
    padding: 0 30px 40px 0;
    margin: 0;
    font-size: 1.4rem;
    font-weight: 500;
    li {
      list-style: none;
      a {
        color: $footerText;
        text-decoration: underline;
      }
    }
  }
  .copy {
    align-self: flex-end;
    margin-top: 40px;
    padding-bottom: 45px;
  }
  #newsletter {
    width: 320px;
    h5 {
      margin: 0 0 3px;
    }
    .mdl-textfield {
      padding: 0;
    }
    #newsletterEmailInput {
      color: $text;
      background-color: $background;
      padding: 5px;
    }
  }
  #footer-contacts {
    img {
      width: 23px;
      height: 23px;
    }
    li {
      margin: 0;
      a {
        text-decoration: none;
      }
    }
  }
  #footer-antikvarnitsa {
    img {
      width: 118px;
      height: 79px;
      margin-top: -7px;
      margin-left: -5px;
    }
  }
}

.price{
  font-weight: 500;
  color: $price;
}

section.info {
  p {
    font-size: 1.5rem;
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}
.featherlight {
  button.featherlight-inner.featherlight-close {
    background-color: $button;
    color: $textHeader;
    padding: 5px 10px;
    width: auto;
    font-size: 1.5rem;
    float: right;
  }
}

.mdl-grid {
  padding: 0 !important;
}
.menu-icon {
  display: none;
  color: $text !important;
  .material-icons {
    pointer-events: none;
    color: $buttonColored;
  }
}

.pagination {
  text-align: center;
  margin-top: 1.2rem;
  font-size: 1.5rem;
  line-height: 2.9rem;
  a {
    padding: 5px 10px;
    color: $buttonColoredRound;
    &:hover {
      background-color: rgba(196,188,167,0.2);
      text-decoration: none;
    }
  }
  a.current {
    color: $textHeader;
    background-color: $buttonColoredRound;
    border: 1px solid $buttonColoredRound;
    &:hover {
      text-decoration: none;
    }
  }
}
.mdl-snackbar {
  opacity: 0.9;
  button.mdl-snackbar__action {
    padding: 14px 14px 12px 14px;
    margin-right: 4px;
    background: $footerBackground;
    color: $footerText;
  }
}
.mdl-textfield.is-invalid {
  .mdl-textfield__input {
    border-color: $error;
  }
  .mdl-textfield__label {
    color: $error;
  }
}
.mdl-textfield__error {
  color: $error;
}

#logo img {
    width:110px;
    margin-left: -4px;
}

@media screen and (max-width: 800px) {
  #logo span {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  footer {
    padding: 40px 20px;
  }
  main{
    width: 100%;
    box-sizing: border-box;
  }
  .mdl-layout .mdl-layout__header-row{
    padding-left: 10px;
    padding-right: 25px;
  }
  #headTop {
    display: none;
  }

  div.search-box {
    width: 50%;
    a.advanced-search {
      display: none;
    }
    .is-focused {
      #search-field {
        border-bottom: 1px solid #fff;
      }
    }
    #search-field {
      border-radius: 0;
      background-color: transparent;
      color: #ffffff;
      border-bottom: 1px solid #cccccc;
      padding-left: 0;
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #cccccc;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #cccccc;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #cccccc;
      }
    }
    .mdl-textfield {
      .mdl-button {
        bottom: -4px;
      }
    }
  }
}
  @media screen and (max-width: 900px) {
    main {
      padding: 45px 40px 10px;
    }
    #basketCaption {
      display: inline !important;
    }
    #categories {
      height: 40px;
      &.mdl-layout__header-row {
        .mdl-navigation {
          .category {
            flex-direction: column;
            align-self: flex-start;
            background-color: $background;
            &.active {
              margin-right: -132px;
              width: 300px;
              position: relative;
              z-index: 100;
              .menu-icon {
                i.material-icons::before {
                  content: "close" !important;
                }
              }
              box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
              .menu-item, .menu-item.menu-item-more, .menu-item-fold {
                display: block;
              }
              .menu-item-more {
                //display: none !important;
              }
            }
            li {
              padding: 4px 20px;
              margin-right: 0;
            }
            > li {
              font-size: 1.5rem;
              margin-right: 0 !important;
              a {
                i.material-icons {
                  display: none;
                }
              }
            }
            .menu-item, .menu-item.menu-item-more, .menu-item-fold {
              display: none;
            }
            .menu-icon {
              display: block;
              cursor: default;
            }
            .subcategory.mdl-menu {
              padding: 0;
              display: block !important;
              position: relative;
              box-shadow: none;
              li {
                font-size: 1.5rem !important;
                margin-right: 0 !important;
                padding: 0 0 0 20px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .mdl-layout__header-row {
      padding-right: 40px;
      height: 64px;
      .mdl-navigation__link:first-of-type {
        padding-left: 0;
      }
    }
    #categories {
      .mdl-navigation {
        .category {
          > li {
            font-size: 1.2rem;
            margin-right: 35px !important;
          }
          .menu-item-fold {
            display: none;
          }
          li.menu-item-more {
            display: block;
            cursor: default;
            i.material-icons {
              cursor: default !important;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    body {
      h1 {
        font-size: 2.9rem;
      }
      h2 {
        font-size: 2.2rem;
      }
    }
  }

  @media screen and ( max-resolution: 150dpi)
  {
    .mdl-layout__header, footer, .mdl-button--raised.mdl-button--accent, .mdl-button--fab, .book-details-card .mdl-chip, .mdl-button--raised.mdl-button--colored {
      background-image: none !important;
    }
  }
