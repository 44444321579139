@import "colors.scss";
.book-card {
	margin: 0 20px 20px 0;
	flex-basis:180px;
	overflow: visible;
  	text-decoration: none;
	color: inherit;
	&:hover{
		text-decoration: none;
	}
  .mdl-button {
  	border-radius: 0;
  }
  .author {
    color: inherit;
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: uppercase;
  }
  .image-container {
    width: 100%;
  	display: flex;
    align-items: center;
  	background-color: $missingBookCoverBackground;
  	height: 200px;
  	text-align: center;

    img {
    	display: block;
    	margin: 0 auto;
    	max-height: 200px;
    	max-width: 180px;
    }
  }

  .mdl-card__actions {
  	padding: 0;
  	border: 0;
    button {
    	width: 100%;
    }
  }
  .mdl-card__title {
  	padding: 12px 12px 0 12px;
    color: inherit;
    h2 {
    	font-size: 1.4rem;
    	font-weight: 500;
    	display: block;
      span {
      	font-size: 1.2rem;
      	display: block;
      	font-weight: 400;
		  margin-top: 5px;
      }
    }
  }
  .mdl-card__text {
  	padding: 10px 12px 0 12px;
  	width: 100%;
    box-sizing: border-box;
    &.price {
      padding-top: 0;
    }
    a {
      font-size: 1.2rem;
      font-weight: 600;
    }
    h4 {
		margin: 0;
		color: $price; // rgb(82, 51, 76);
		font-weight: 500;
		font-size: 1.7rem;
		text-align: center;
		span.currency {
			font-size: 1.3rem;
		}
    }
  }
}
.highlight {
  background-color: $basketBadge;
  color: white;
  text-align: center;
  width: 100%;
  h2 {
    margin: 0;
    font-size: 3rem;
  }
  p {
    margin: 0 0 5px 0;
    a {
      color: white;
      text-decoration: underline;
    }
    a:hover {
      text-decoration: none;
    }
  }
}
.all_books {
  align-items: center;
  flex-direction: row;
  div {
    font-size: 2rem;
    line-height: 2.3rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #7a1632;
    text-align: center;
  }
}
.all_books:hover {
  background-color: rgba(196,188,167,0.5)
}
.price-range-help {
	color: $priceRangeIcon;
    font-size: 1.7rem;
	position: relative;
	top: 4px;
}
.missing-cover {
	height: 200px;
	line-height: 200px;
	font-size: 8.4rem;
	color: $missingBookCoverText;
	margin: 0 auto;
}

.book-preview-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.book-preview-list h1 {
	width: 100%;
}
#lastestBooks, #recommendedBooks {
  > h2 {
    width: 100%;
    font-size: 3rem;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 700px) {
	.book-card {
		width: 230px;
	}
	.book-preview-list {
		justify-content: space-around;
	}
}
