$maroon: rgba(142, 85, 114, 1);
$teal: rgba(98, 146, 158, 1);
$light: rgba(250, 245, 245, 1);
$mustard: rgba(194, 185, 127, 1);
$brown: rgba(101, 85, 96, 1);

//main colors
$headerLine1: #610027;
$headerLine2: #7a1632;
$footerBackground: #61435c;
$buttonColoredRound: #99917a;
$button: $footerBackground;
$link: lighten(desaturate(adjust-hue($headerLine2, 2), 9.87), 8.63);
$buttonColored: $headerLine2;

//secondary colors
$text: rgba(79, 79, 79, 1);
$footerText: rgba(255, 255, 255, 1);
$background: rgba(255, 255, 255, 1);
$basketHoverBackground: #f4f4f4;
$buttonRemoveFromBasket: #DF484E;
$error: #ff0044;
$priceRangeIcon: rgba(#99917a,.6);

//derived colors
$tooltips: $headerLine2;
$cookieBackground: $text;
$cookieText: $background;
$cookieLink: $background;
$cookieButton: $headerLine2;
$linksButtonsHeader: $footerText;
$textHeader: $footerText;
$basketIcon: $headerLine2;
$basketBadge: rgba($footerBackground,0.7);
$price: (darken(saturate(adjust-hue($footerBackground, 2), 5.02), 6.08));
$missingBookCoverBackground: saturate(rgba( $priceRangeIcon, .15 ), 10%);
$missingBookCoverText: lighten($priceRangeIcon, 100%);